import PropTypes from "prop-types"
import React from "react"
import { getThumb } from "../../utils"
import EditorialImage from "../collection-thumbnail-editorial/collection-thumbnail-editorial"
import CollectionThumbnailItem from "../collection-thumbnail-item/collection-thumbnail-item"

const CollectionThumbnailsGrid = ({ isMobile, products, images = [] }) => {
  return (
    <div className="grid grid-cols-2 lg:grid-cols-3">
      {products.map((product, i) => {
        return (
          <React.Fragment key={i}>
            {images &&
              images.map(({ id, url, place, type, text }) =>
                isMobile && place?.phone === i ? (
                  <EditorialImage
                    key={id}
                    type={type}
                    url={url}
                    text={text}
                    isMobile={isMobile}
                  />
                ) : !isMobile && place?.desktop === i ? (
                  <EditorialImage key={id} type={type} url={url} text={text} />
                ) : null
              )}

            <CollectionThumbnailItem
              title={product?.name}
              link={`/product/${product?.name
                ?.replace(/[^A-Z0-9]+/gi, "-")
                .toLowerCase()}-${product?.productColor?.name
                .replace(/[^A-Z0-9]+/gi, "-")
                .toLowerCase()}`}
              price={product?.pricePoint}
              materials={product?.materials}
              colors={product?.productColor?.name}
              badge={product?.bConscious}
              id={product?.code}
              key={product?.id}
              thumbnail={getThumb(product?.assets)}
            />
          </React.Fragment>
        )
      })}
    </div>
  )
}

CollectionThumbnailsGrid.propTypes = {
  products: PropTypes.array.isRequired,
  images: PropTypes.array,
}

export default CollectionThumbnailsGrid
